import * as React from 'react'
import { Link } from 'gatsby'

// styles

// markup
const NotFoundPage = () => {
  return (
    <main className="min-h-[40vh] bg-background">
      <div className="mx-auto text-center whitespace-pre-line max-w-master px-mobile sm:px-tablet lg:px-desktop font-primary">
        <title>Not found</title>
        <h1 className="py-8 mx-auto text-center md:max-w-screen-sm sm:py-10 text-headlineH2">
          Page not found
        </h1>
        <p className="text-lg font-primary">
          Sorry{' '}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{' '}
          we couldn’t find what you were looking for.
          <br />
          {process.env.NODE_ENV === 'development' ? (
            <>
              <br />
              Try creating a page in <code>src/pages/</code>.
              <br />
            </>
          ) : null}
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </div>
    </main>
  )
}

export default NotFoundPage
